@use '../../scss/' as *;
/* Modal Popup  ----------
-------------------------*/
.modal-dialog {
    width: 100%;
    // max-width: 500px;   
}
.modal-content{
    border-radius: 20px !important;
    border: none !important;
    box-shadow: 0px 3px 16px rgb(47 83 109 / 12%) !important;
    background:  #fff;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 500px;
}
.is_dark .modal-content{
    border-radius: 20px !important;
    border: none !important;
    box-shadow: 0px 3px 16px rgb(47 83 109 / 12%) !important;
    background:  #222232;
}

.modal-body {
    padding: 40px !important;
    .price {
        font-weight: 700;
        font-size: 18px;
    }
    .quantity{
        color: var(--primary-color1);
        background-color: transparent;
    }
    .btn.btn-primary{
        width: 100%;
        border-radius: 20px;
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
        padding: 10px 20px;
        background-color: var(--primary-color3) !important;
        @include transition3;
        outline: none;
        border: none ;
    }
}

.modal-content input,
.modal-content p {
    margin-bottom: 15px;
}

.modal-body .btn.btn-primary:hover{
    opacity: 0.8;
}

.modal-content .close{
    overflow: hidden;
    height: 30px;
    width: 30px;
    border-radius: 50px;
    position: absolute;
    // right: -15px;
    // top: -15px;
    background-color: var(--primary-color3);
    border: none;
    outline: none;
    z-index: 10 !important;
    opacity: 1;
    box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
}

.modal-content .close:hover{
    opacity: 1;
}

.modal-content .close span{
    font-size: 15px;
    color: #fff;
}

.is_dark .modal-content .close{
    background-color: var(--primary-color3);
}

.modal-body h2{
    text-align: center;
    font-weight: 600;
}

.modal{
    z-index: 999999 !important;
}

.modal-open .modal{
    overflow: hidden !important;
    padding-right: 0 !important;
}

.modal-dialog-centered{
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
    max-width: 500px;
    margin: 1.75rem auto;
}
.modal.fade.popup.show {
    padding-right: 0 !important;
}