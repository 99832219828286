@use '../../scss/' as *;
/* button 
--------------------------------------------------*/
.sc-button {
    display      : inline-block;
    border       : 1px solid var(--primary-color9);
    color        : var(--primary-color);
    background-color: var(--primary-color9);
    box-sizing   : border-box;
    padding      : 18px 36px;
    border-radius: 5px;
    @include transition3;
    span {
        font-weight       : 500;
        font-size         : 16px;
        line-height       : 18px;
        position          : relative;
        @include transition3;
    }
    &:hover,
    &.active {
        border-color: 1px solid var(--primary-color9);
        background-color: transparent;
        color: var(--primary-color);
        // outline: 0 none;
    }
}

.sc-button.btn-4:hover,
.sc-button.btn-3:hover,
.sc-button.btn-5 {
    background-color: #2D333B;
    border: 1px solid #2D333B;
    color: #fff;
}

.sc-button.btn-2 {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: var(--primary-color6);
}

.sc-button span,
.sc-button.btn-7:hover span,
.is_dark .sc-button {
    color: var(--primary-color);
}

.is_dark .sc-button.btn-2:hover,
.sc-button.btn-5:hover,
.is_dark .sc-button.btn-1:hover,
.sc-button.fl-button:hover{
    border: 1px solid #fff;
    background-color: #fff;
    color: var(--primary-color3);
    box-shadow: 0px 14px 14px rgba(45, 51, 59, 0.18);
}
.sc-button.btn-3,
.sc-button.btn-5,
.sc-button.fl-button {
    box-shadow: 0px 14px 14px rgba(45, 51, 59, 0.18);
    color: #fff;
}

.sc-button.btn-2:hover {
    background-color: var(--primary-color3);
    border       : 1px solid var(--primary-color3);
    color: var(--primary-color);
}

.sc-button.btn-2:hover span {
    color: var(--primary-color);
}

.is_dark .sc-button.btn-2:hover span,
.sc-button.btn-5:hover span,
.sc-button.fl-button:hover span,
.sc-button.fl-button.active span {
    color: var(--primary-color);
    z-index: 40;
}

.sc-button.btn-2 span {
    color: var(--primary-color2);
}

.sc-button.btn-6 {
    background-color: #231F1E08;
    border: 1px solid #231F1E08;
}

.sc-button.btn-6.bt {
    background: #231F1E12;
    border: 1px solid #231F1E12;
}

.sc-button.btn-4 {
    background-color: #FFFFFF1A;
    border: 1px solid var(--primary-color6);
}

.sc-button.btn-6.tf-style,
.is_dark .sc-button.btn-6 {
    background-color: #FFFFFF1A;
    border: 1px solid var(--primary-color6);
}

.sc-button.btn-6 span {
    color: var(--primary-color8);
}

.sc-button.btn-6.tf-style span,
.sc-button.btn-6:hover span {
    color: #fff;
}

.sc-button.btn-7:hover,
.sc-button.btn-6:hover {
    background-color: var(--primary-color3);
    border: 1px solid var(--primary-color3);
}

.sc-button.btn-7 {
    background:  var(--primary-color5);
    border: 1px solid var(--primary-color5);
}
  
.sc-button.btn-7 span {
    color: var(--primary-color4);
}
